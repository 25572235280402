// src/hooks/useAuth.ts
import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '@store/store';
import { setToken, setUser, clearAuth } from '@store/slices/auth.slice';
import { useLoginMutation, useLogoutMutation } from '@api/auth.api';
import { User } from '../types/user.types';
import { useGetCurrentUserQuery } from '@api/users.api';
import { useEffect } from 'react';

interface UseAuthReturn {
  isAuthenticated: boolean;
  token: string | null;
  user: User | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuth = (): UseAuthReturn => {
  const dispatch = useDispatch();
  const token = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.auth.user);

  const [loginMutation] = useLoginMutation();
  const [logoutMutation] = useLogoutMutation();

  const { data: currentUser } = useGetCurrentUserQuery(undefined, {
    skip: !token
  });

  useEffect(() => {
    if (currentUser) {
      dispatch(setUser(currentUser));
    }
  }, [currentUser, dispatch]);

  const isAuthenticated = Boolean(token && user);

  const login = async (email: string, password: string) => {
    try {
      const response = await loginMutation({ email, password }).unwrap();
      dispatch(setToken(response.token));

    } catch (error) {
      throw error;
    }
  };

  const logout = async () => {
    try {
      await logoutMutation().unwrap();
      dispatch(clearAuth());
    } catch (error) {
      throw error;
    }
  };

  return {
    isAuthenticated,
    token,
    user,
    login,
    logout
  };
};
