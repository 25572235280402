// src/components/pages/Auth/VerificationPage/VerificationPage.tsx
import React from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { AuthTemplate } from '@components/templates/AuthTemplate/AuthTemplate';
import PhoneVerificationForm from '@components/organisms/Forms/PhoneVerificationForm/PhoneVerificationForm';
import { EmailVerificationForm } from '@components/organisms/Forms/EmailVerificationForm/EmailVerificationForm';

export const VerificationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { phone, email, step } = location.state || {};

  if (!phone && !email) {
    return <Navigate to="/register" replace />;
  }

  const handlePhoneVerificationSuccess = () => {
    // После успешной верификации телефона переходим к верификации email
    navigate('/verify', {
      state: {
        phone,
        email,
        step: 'email'
      },
      replace: true
    });
  };

  const handleEmailVerificationSuccess = () => {
    // После успешной верификации email переходим к списку пространств
    navigate('/spaces', { replace: true });
  };

  return (
    <AuthTemplate>
      <h1 className="text-2xl mb-4">
        {step === 'phone' ? 'Подтверждение телефона' : 'Подтверждение Email'}
      </h1>
      {step === 'phone' ? (
        <PhoneVerificationForm 
          phone={phone}
          onSuccess={handlePhoneVerificationSuccess}
        />
      ) : (
        <EmailVerificationForm 
          email={email}
          onSuccess={handleEmailVerificationSuccess}
        />
      )}
    </AuthTemplate>
  );
};
