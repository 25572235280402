import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarItemProps {
  icon: string;
  label: string;
  to: string;
  isActive: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  label,
  to,
  isActive
}) => {
  return (
    <NavLink
      to={to}
      className={`flex items-center px-4 py-3 text-sm font-medium rounded-md transition-all duration-200 ${
        isActive
          ? 'bg-blue-50 text-blue-700'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
      }`}
    >
      <span className="mr-3">{icon}</span>
      {label}
    </NavLink>
  );
};