import React from 'react';
import { useLocation } from 'react-router-dom';
import { SidebarItem as SidebarItemComponent } from '@components/molecules/SidebarItem/SidebarItem';

interface SidebarItemData {
  id: string;
  label: string;
  icon: string;
  path: string;
}

const sidebarItems: SidebarItemData[] = [
  { id: 'my-channels', label: 'Мои каналы', icon: '📱', path: '/channels' },
  { id: 'competitors', label: 'Каналы конкурентов', icon: '🔍', path: '/competitors' },
  { id: 'analytics', label: 'Аналитика', icon: '📊', path: '/analytics' },
  { id: 'content-plans', label: 'Контент-планы', icon: '📅', path: '/content-plans' },
  { id: 'billing', label: 'Биллинг', icon: '💳', path: '/billing' },
  { id: 'settings', label: 'Настройки', icon: '⚙️', path: '/settings' }
];

interface SidebarProps {
  spaceId: string;
}

export const Sidebar: React.FC<SidebarProps> = ({ spaceId }) => {
  const location = useLocation();

  return (
    <aside className="w-64 bg-white border-r border-gray-200 min-h-screen fixed left-0 top-16">
      <nav className="p-4">
        <div className="space-y-1">
          {sidebarItems.map((item) => {
            const fullPath = `/spaces/${spaceId}${item.path}`;
            const isActive = location.pathname === fullPath;

            return (
              <SidebarItemComponent
                key={item.id}
                icon={item.icon}
                label={item.label}
                to={fullPath}
                isActive={isActive}
              />
            );
          })}
        </div>
      </nav>
    </aside>
  );
};