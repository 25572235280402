// src/components/molecules/SocialAuthButton/SocialAuthButton.tsx
import React from 'react';

type SocialAuthProvider = "yandex" | "vk" | "telegram";

interface SocialAuthButtonProps {
  provider: SocialAuthProvider;
  onClick: () => void;
}

export const SocialAuthButton: React.FC<SocialAuthButtonProps> = ({
  provider,
  onClick
}) => {
  // Конфигурация для каждого провайдера
  const config = {
    yandex: {
      text: 'Яндекс',
      className: 'bg-[#FC3F1D] hover:bg-[#E63611] text-white',
      icon: (
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
          <path d="M13.4 21h-2.8v-8.9L5.3 3h3.2l3.5 6.3L15.5 3h3.2l-5.3 9.1V21z" />
        </svg>
      )
    },
    vk: {
      text: 'ВКонтакте',
      className: 'bg-[#0077FF] hover:bg-[#0066DB] text-white',
      icon: (
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
          <path d="M15.07 2H8.93C3.33 2 2 3.33 2 8.93v6.14C2 20.67 3.33 22 8.93 22h6.14c5.6 0 6.93-1.33 6.93-6.93V8.93C22 3.33 20.67 2 15.07 2zm3.08 14.27h-1.46c-.55 0-.72-.44-1.71-1.43-.86-.84-1.24-.95-1.45-.95-.29 0-.38.08-.38.49v1.31c0 .35-.11.57-1.04.57-1.54 0-3.25-.94-4.45-2.67-1.81-2.54-2.3-4.46-2.3-4.85 0-.21.08-.41.49-.41h1.46c.37 0 .51.17.65.57.71 2.05 1.89 3.84 2.38 3.84.18 0 .27-.08.27-.55V9.33c-.06-.99-.58-1.07-.58-1.43 0-.17.14-.34.37-.34h2.29c.31 0 .42.17.42.54v2.89c0 .31.14.42.23.42.18 0 .34-.11.68-.45 1.04-1.17 1.79-2.98 1.79-2.98.1-.21.27-.41.68-.41h1.46c.44 0 .54.23.44.54-.18.85-1.96 3.36-1.96 3.36-.15.25-.21.37 0 .65.15.21.66.65 1 1.04.62.71 1.1 1.3 1.23 1.71.11.41-.08.62-.49.62z"/>
        </svg>
      )
    },
    telegram: {
      text: 'Telegram',
      className: 'bg-[#27A6E6] hover:bg-[#1A93D2] text-white',
      icon: (
        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69.01-.03.01-.14-.07-.2-.08-.06-.19-.04-.27-.02-.11.02-1.93 1.23-5.46 3.62-.51.35-.98.53-1.4.51-.46-.01-1.35-.26-2.01-.48-.81-.27-1.46-.42-1.4-.88.03-.25.38-.51 1.07-.78 4.19-1.82 6.98-3.03 8.36-3.62 3.96-1.7 4.79-1.99 5.33-2.01.12 0 .37.03.54.18.14.12.18.28.2.45-.02.07-.02.21-.03.27z"/>
        </svg>
      )
    }
  };

  const buttonConfig = config[provider];

  return (
    <button
      type="button"
      onClick={onClick}
      className={`
        w-full flex items-center justify-center gap-2
        px-4 py-2 rounded-md
        text-sm font-medium
        transition-colors duration-200
        ${buttonConfig.className}
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
      `}
    >
      {buttonConfig.icon}
      <span>Войти через {buttonConfig.text}</span>
    </button>
  );
};
