// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '@api/auth.api';
import { spacesApi } from '@api/spaces.api';

import { usersApi } from '@api/users.api';
import authReducer from '@store/slices/auth.slice';
import themeReducer from '@store/slices/theme.slice';
import { plansApi } from '@api/plans.api';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [spacesApi.reducerPath]: spacesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    auth: authReducer,
    theme: themeReducer,
    [plansApi.reducerPath]: plansApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'authApi/executeMutation',
          'spacesApi/executeMutation',
          'verificationApi/executeMutation',
          'usersApi/executeMutation'
        ]
      }
    }).concat(
      authApi.middleware,
      spacesApi.middleware,
      usersApi.middleware,
      plansApi.middleware
    )
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
