// src/components/molecules/FormField/FormField.tsx
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface FormFieldProps {
  label: string;
  error?: string;
  register: UseFormRegisterReturn;
  type?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const FormField: React.FC<FormFieldProps> = ({ label, error, register, type = 'text', placeholder, onChange, className }) => (
  <div className={`mb-4 ${className || ''}`}>
    <label className="block text-gray-700 mb-1">{label}</label>
    <input
      className="w-full border border-gray-300 p-2 rounded"
      type={type}
      {...register}
      placeholder={placeholder}
      onChange={onChange}
    />
    {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
  </div>
);
