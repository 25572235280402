// src/components/templates/SpaceTemplate/SpaceTemplate.tsx
import React from 'react';
import { Navbar } from '@components/organisms/Navbar/Navbar';
import { Sidebar } from '@components/organisms/Sidebar/Sidebar';
import { LoadingSpinner } from '@components/atoms/LoadingSpinner/LoadingSpinner';

interface SpaceTemplateProps {
  children: React.ReactNode;
  spaceId: string;
  isLoading?: boolean;
}

export const SpaceTemplate: React.FC<SpaceTemplateProps> = ({
  children,
  spaceId,
  isLoading = false
}) => {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar spaceId={spaceId} />
      <div className="flex pt-16">
        <Sidebar spaceId={spaceId} />
        <main className="ml-64 flex-1 p-6">
          {children}
        </main>
      </div>
    </div>
  );
};
