// src/components/pages/Spaces/SpacesListPage/SpacesListPage.tsx
import React, { useState } from 'react';
import { MainTemplate } from '@components/templates/MainTemplate/MainTemplate';
import { EmptyStateCard } from '@components/molecules/EmptyStateCard/EmptyStateCard';
import { CreateSpaceButton } from '@components/molecules/CreateSpaceButton/CreateSpaceButton';
import { useGetUserSpacesQuery } from '@api/users.api';
import { CreateSpaceWizard } from '@components/organisms/CreateSpaceWizard/CreateSpaceWizard';
import { Space } from '../../../../types/space.types';
import { formatDate, pluralizeMembers } from '@utils/formatters';
import { useNavigate } from 'react-router-dom';

const SpaceCard: React.FC<{ space: Space }> = ({ space }) => {
  const navigate = useNavigate();

  const handleSpaceClick = () => {
    navigate(`/spaces/${space.id}`);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100 hover:shadow-md transition-all duration-300 relative overflow-hidden group">
      <div className="absolute top-0 right-0 w-24 h-24 bg-gradient-to-br from-blue-100 to-indigo-100 rounded-bl-full opacity-50 transition-opacity group-hover:opacity-75" />
      
      <div className="relative">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-xl font-semibold text-gray-900">{space.name}</h3>
          <span className="text-sm text-gray-500">
            ID: {String(space.id).substring(0, 8)}
          </span>
        </div>

        {space.description && (
          <p className="text-gray-600 mb-4 line-clamp-2">{space.description}</p>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2 text-sm text-gray-500">
            <span>👥 {space.members?.length || 0} {pluralizeMembers(space.members?.length || 0)}</span>
          </div>
          <div className="text-sm text-gray-500">
            {formatDate(space.created_at)}
          </div>
        </div>

        <button
          onClick={handleSpaceClick}
          className="mt-4 w-full bg-gradient-to-r from-blue-500 to-indigo-500 hover:from-blue-600 hover:to-indigo-600 text-white font-medium py-2 px-4 rounded-md transition-all duration-300 flex items-center justify-center group-hover:shadow-lg"
        >
          <span>Перейти в пространство</span>
          <span className="ml-2">→</span>
        </button>
      </div>
    </div>
  );
};

export const SpacesListPage: React.FC = () => {
  const { data: spaces, isLoading } = useGetUserSpacesQuery();
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [isInfoExpanded, setIsInfoExpanded] = useState(true);

  const handleCreateSpace = () => {
    setIsWizardOpen(true);
  };

  const toggleInfo = () => {
    setIsInfoExpanded(!isInfoExpanded);
  };

  return (
    <MainTemplate>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Мои пространства</h1>
          <CreateSpaceButton onClick={handleCreateSpace} />
        </div>

        {isLoading ? (
          <div>Загрузка...</div>
        ) : spaces?.length === 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <EmptyStateCard
              emoji="👋"
              title="Добро пожаловать!"
              description="Давайте создадим ваше первое пространство для управления контентом"
            />
            <EmptyStateCard
              emoji="📱"
              title="Подключите соцсети"
              description="Подключите ваши социальные сети для автоматизации постинга"
            />
            <EmptyStateCard
              emoji="✨"
              title="Используйте AI"
              description="Создавайте контент с помощью искусственного интеллекта"
            />
          </div>
        ) : (
          <>
            <div className="bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg mb-8 transition-all duration-300">
              <div 
                className="p-4 flex items-center justify-between cursor-pointer hover:bg-blue-100/50 rounded-t-lg"
                onClick={toggleInfo}
              >
                <div className="flex items-center space-x-2">
                  <span className="text-2xl">🚀</span>
                  <h2 className="text-xl font-semibold text-gray-900">
                    Что такое пространство?
                  </h2>
                </div>
                <button className="text-gray-500 hover:text-gray-700 transition-colors">
                  <span className={`transform transition-transform duration-200 block ${isInfoExpanded ? 'rotate-180' : ''}`}>
                    ▼
                  </span>
                </button>
              </div>
              
              {isInfoExpanded && (
                <div className="p-4 pt-2 border-t border-blue-100">
                  <p className="text-gray-600 mb-3">
                    Пространство - это ваша персональная рабочая область для управления контентом. Здесь вы можете:
                  </p>
                  <ul className="space-y-2 text-gray-600">
                    <li className="flex items-center">
                      <span className="mr-2">📊</span>
                      Управлять несколькими каналами и получать единую статистику
                    </li>
                    <li className="flex items-center">
                      <span className="mr-2">📅</span>
                      Формировать контент-планы и автоматизировать публикации
                    </li>
                    <li className="flex items-center">
                      <span className="mr-2">🤖</span>
                      Использовать AI для генерации и оптимизации контента
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {spaces?.map((space) => (
                <SpaceCard key={space.id} space={space} />
              ))}
            </div>
          </>
        )}
      </div>

      {isWizardOpen && (
        <CreateSpaceWizard
          isOpen={isWizardOpen}
          onClose={() => setIsWizardOpen(false)}
        />
      )}
    </MainTemplate>
  );
};
