import React from 'react';

export const Competitors: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Каналы конкурентов</h1>
      <div className="bg-white rounded-lg shadow p-4">
        <div className="space-y-4">
          {/* Здесь будет список конкурентов */}
          <p className="text-gray-600">
            Отслеживайте активность и метрики каналов ваших конкурентов
          </p>
        </div>
      </div>
    </div>
  );
}; 