import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetPlansQuery, useGetCurrentPlanQuery } from '@api/plans.api';
import { Plan } from '../../../types/plan.types';
import { formatFeatureValue } from '../../../utils/formatters';

export const Billing: React.FC = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const { data: plans = [], isLoading: isPlansLoading, error: plansError } = useGetPlansQuery();
  const { data: currentPlanData, isLoading: isCurrentPlanLoading, error: currentPlanError } = useGetCurrentPlanQuery(spaceId!, {
    skip: !spaceId
  });

  const currentPlan: Plan = (currentPlanData?.plan || currentPlanData) as Plan;

  const isLoading = isPlansLoading || isCurrentPlanLoading;

  const renderFeatureValue = (value: string) => {
    const formattedValue = formatFeatureValue(value);
    if (formattedValue === '✓') return <span className="text-green-500">✓</span>;
    if (formattedValue === '×') return <span className="text-red-500">×</span>;
    return formattedValue;
  };

  if (plansError || currentPlanError) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border border-red-200 rounded-md p-4 text-red-700">
          Произошла ошибка при загрузке данных. Пожалуйста, попробуйте позже.
        </div>
      </div>
    );
  }

  return (
    <div className="pt-2 px-6 max-w-full overflow-x-auto">
      {isLoading ? (
        <div className="text-center py-8">Загрузка...</div>
      ) : (
        <div className="space-y-8">
          {/* Текущий тариф */}
          {currentPlan && (
            <div className="bg-white rounded-lg shadow p-4">
              <h2 className="text-xl font-bold mb-2">Текущий тариф</h2>
              <div>
                <div className="text-2xl font-bold text-blue-600">{currentPlan.name}</div>
                <div className="text-gray-600">{currentPlan.description}</div>
                <div className="text-lg font-medium mt-2">{currentPlan.price} ₽/месяц</div>
              </div>
            </div>
          )}

          {/* Сравнение тарифов */}
          {plans && plans.length > 0 && (
            <div className="bg-white rounded-lg shadow">
              <div className="overflow-x-auto">
                <table className="w-full min-w-[600px]">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="p-4 text-left font-semibold">Функции</th>
                      {plans.map((plan) => (
                        <th 
                          key={`plan-header-${plan.id}`} 
                          className={`p-4 text-center font-semibold ${currentPlan?.id === plan.id ? 'bg-blue-100' : ''}`}
                        >
                          <div className="text-xl font-bold">{plan.name}</div>
                          <div className="text-lg font-semibold text-gray-900 my-2">{plan.price} ₽/месяц</div>
                          <button 
                            className={`${
                              currentPlan?.id === plan.id 
                                ? 'bg-gray-400 cursor-not-allowed' 
                                : 'bg-blue-600 hover:bg-blue-700'
                            } text-white px-4 py-2 rounded-md transition-colors`}
                            disabled={currentPlan?.id === plan.id}
                          >
                            {currentPlan?.id === plan.id ? 'Текущий тариф' : 'Выбрать тариф'}
                          </button>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {plans[0].features
                      .filter((feature, index, self) => 
                        index === self.findIndex(f => f.id === feature.id)
                      )
                      .map((feature) => (
                        <tr key={feature.id}>
                          <td className="p-4 font-semibold text-gray-700">{feature.description}</td>
                          {plans.map((plan) => {
                            const planFeature = plan.features.find(f => f.id === feature.id);
                            return (
                              <td 
                                key={`${plan.id}-${feature.id}`} 
                                className={`p-4 text-center ${currentPlan?.id === plan.id ? 'bg-blue-100' : ''}`}
                              >
                                {renderFeatureValue(planFeature?.value || '')}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
