// src/components/organisms/Navbar/NavbarDropdown.tsx
import React, { useState } from 'react';
import { useAuth } from '@hooks/useAuth';
import { Link } from 'react-router-dom';

interface NavbarDropdownProps {
  userName: string;
  userAvatar?: string;
}

export const NavbarDropdown: React.FC<NavbarDropdownProps> = ({
  userName,
  userAvatar
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { logout } = useAuth();

  return (
    <div className="ml-4 relative">
      <button 
        className="flex items-center focus:outline-none" 
        onClick={() => setIsOpen(!isOpen)}
      >
        {userAvatar ? (
          <img
            src={userAvatar}
            alt={userName}
            className="w-8 h-8 rounded-full"
          />
        ) : (
          <span className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center text-gray-600">
            {userName.charAt(0)}
          </span>
        )}
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            <div className="px-4 py-2 text-sm text-gray-700 border-b">
              {userName}
            </div>
            <Link 
              to="/profile" 
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Профиль
            </Link>
            <button
              onClick={logout}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Выйти
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
