// src/api/spaces.api.ts
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';
import { API_ROUTES } from '@config/api.routes';
import { Space, SpaceMember } from '../types/space.types';

export const spacesApi = createApi({
  reducerPath: 'spacesApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Space', 'SpaceMember'],
  endpoints: (builder) => ({
    getSpaces: builder.query<Space[], void>({
      query: () => API_ROUTES.SPACES.BASE,
      providesTags: ['Space']
    }),

    getSpaceById: builder.query<Space, string>({
      query: (id) => API_ROUTES.SPACES.GET_BY_ID(id),
      providesTags: (result, error, id) => [{ type: 'Space', id }]
    }),

    createSpace: builder.mutation<Space, Partial<Space>>({
      query: (data) => ({
        url: API_ROUTES.SPACES.BASE,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Space']
    }),

    updateSpace: builder.mutation<Space, { id: string; data: Partial<Space> }>({
      query: ({ id, data }) => ({
        url: API_ROUTES.SPACES.GET_BY_ID(id),
        method: 'PUT',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Space', id }]
    }),

    deleteSpace: builder.mutation<void, string>({
      query: (id) => ({
        url: API_ROUTES.SPACES.GET_BY_ID(id),
        method: 'DELETE'
      }),
      invalidatesTags: ['Space']
    }),

    getSpaceMembers: builder.query<SpaceMember[], string>({
      query: (spaceId) => API_ROUTES.SPACES.MEMBERS.LIST(spaceId),
      providesTags: ['SpaceMember']
    }),

    addSpaceMember: builder.mutation<void, { spaceId: string; userId: string; role: string }>({
      query: ({ spaceId, ...data }) => ({
        url: API_ROUTES.SPACES.MEMBERS.ADD(spaceId),
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['SpaceMember']
    }),

    removeSpaceMember: builder.mutation<void, { spaceId: string; userId: string }>({
      query: ({ spaceId, userId }) => ({
        url: API_ROUTES.SPACES.MEMBERS.REMOVE(spaceId, userId),
        method: 'DELETE'
      }),
      invalidatesTags: ['SpaceMember']
    }),

    updateMemberRole: builder.mutation<void, { spaceId: string; userId: string; role: string }>({
      query: ({ spaceId, userId, role }) => ({
        url: API_ROUTES.SPACES.MEMBERS.UPDATE_ROLE(spaceId, userId),
        method: 'PUT',
        body: { role }
      }),
      invalidatesTags: ['SpaceMember']
    })
  })
});

export const {
  useGetSpacesQuery,
  useGetSpaceByIdQuery,
  useCreateSpaceMutation,
  useUpdateSpaceMutation,
  useDeleteSpaceMutation,
  useGetSpaceMembersQuery,
  useAddSpaceMemberMutation,
  useRemoveSpaceMemberMutation,
  useUpdateMemberRoleMutation
} = spacesApi;
