import React from 'react';

export const Settings: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Настройки</h1>
      <div className="max-w-2xl">
        <div className="bg-white rounded-lg shadow p-6 space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-4">Основные настройки</h3>
            <div className="space-y-4">
              {/* Здесь будут формы настроек */}
              <div className="flex flex-col space-y-2">
                <label className="text-sm font-medium text-gray-700">
                  Название пространства
                </label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-md px-3 py-2"
                  placeholder="Введите название"
                />
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-medium mb-4">Уведомления</h3>
            <div className="space-y-4">
              {/* Здесь будут настройки уведомлений */}
              <div className="flex items-center justify-between">
                <span className="text-gray-700">Email-уведомления</span>
                <button className="bg-gray-200 rounded-full w-12 h-6 transition-colors duration-200" />
              </div>
            </div>
          </div>
          
          <div className="pt-4">
            <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
              Сохранить изменения
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}; 