import { z } from 'zod';

export const registerSchema = z.object({
  email: z
    .string()
    .min(1, 'Email обязателен')
    .email('Неверный формат email'),
  phone: z
    .string()
    .min(1, 'Телефон обязателен')
    .regex(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Телефон должен быть в формате +7 (999) 999-99-99'
    ),
  password: z
    .string()
    .min(8, 'Минимальная длина пароля - 8 символов')
    .regex(/[A-Z]/, 'Пароль должен содержать хотя бы одну заглавную букву')
    .regex(/[a-z]/, 'Пароль должен содержать хотя бы одну строчную букву')
    .regex(/[0-9]/, 'Пароль должен содержать хотя бы одну цифру')
    .regex(
      /[!@#$%^&*]/,
      'Пароль должен содержать хотя бы один специальный символ (!@#$%^&*)'
    ),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: 'Пароли не совпадают',
  path: ['confirmPassword']
});

export const loginSchema = z.object({
  email: z.string().email('Неверный формат email'),
  password: z.string().min(6, 'Минимальная длина пароля - 6 символов')
});

export const verificationSchema = z.object({
  code: z.string()
    .min(6, 'Код должен содержать 6 символов')
    .max(6, 'Код должен содержать 6 символов')
    .regex(/^\d+$/, 'Код должен содержать только цифры')
});

export const phoneVerificationSchema = z.object({
  phone: z.string()
    .regex(
      /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
      'Телефон должен быть в формате +7 (999) 999-99-99'
    ),
  code: z.string()
    .min(6, 'Код должен содержать 6 символов')
    .max(6, 'Код должен содержать 6 символов')
    .regex(/^\d+$/, 'Код должен содержать только цифры')
});

export const emailVerificationSchema = z.object({
  email: z.string()
    .email('Неверный формат email'),
  code: z.string()
    .min(6, 'Код должен содержать 6 символов')
    .max(6, 'Код должен содержать 6 символов')
    .regex(/^\d+$/, 'Код должен содержать только цифры')
});
