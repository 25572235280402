// src/utils/formatters.ts
export function cn(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }
  
export const formatPhoneNumber = (value: string): string => {
  let numbers = value.replace(/\D/g, '');
  
  // Удаляем первую 7 или 8, если она есть
  if (numbers.startsWith('7') || numbers.startsWith('8')) {
    numbers = numbers.slice(1);
  }
  
  // Ограничиваем длину до 10 цифр
  if (numbers.length > 10) {
    numbers = numbers.slice(0, 10);
  }
  
  // Форматируем номер
  let formattedValue = '';
  if (numbers.length > 0) {
    formattedValue = '+7 ';
    if (numbers.length > 0) {
      formattedValue += `(${numbers.slice(0, 3)}`;
    }
    if (numbers.length > 3) {
      formattedValue += `) ${numbers.slice(3, 6)}`;
    }
    if (numbers.length > 6) {
      formattedValue += `-${numbers.slice(6, 8)}`;
    }
    if (numbers.length > 8) {
      formattedValue += `-${numbers.slice(8, 10)}`;
    }
  } else {
    formattedValue = '+7';
  }
  
  return formattedValue;
};
  
export const cleanPhoneNumber = (phone: string): string => {
  // Удаляем все нецифровые символы и оставляем только цифры
  return phone.replace(/\D/g, '');
};
  
export const formatDate = (dateString: string) => {
  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (error) {
    console.error('Ошибка форматирования даты:', error);
    return 'Дата не указана';
  }
};
  
export const pluralizeMembers = (count: number): string => {
  const cases = ['участник', 'участника', 'участников'];
  const num = Math.abs(count) % 100;
  const num1 = num % 10;

  if (num > 10 && num < 20) return cases[2];
  if (num1 > 1 && num1 < 5) return cases[1];
  if (num1 === 1) return cases[0];
  
  return cases[2];
};
  
export const formatFeatureValue = (value: string): string => {
  const valueMap: Record<string, string> = {
    'none': '×',
    'simple': 'базовый',
    'advanced': 'продвинутый',
    'scheduled': 'по расписанию',
    'smart': 'умный',
    'unlimited': 'без ограничений',
    'true': '✓',
    'false': '×'
  };

  return valueMap[value.toLowerCase()] || value;
};
  