// src/components/organisms/Navbar/Navbar.tsx
import React from 'react';
import { NavbarDropdown } from './NavbarDropdown';
import { useAuth } from '@hooks/useAuth';
import { Link } from 'react-router-dom';

interface NavbarProps {
  spaceId?: string;
}

export const Navbar: React.FC<NavbarProps> = ({ spaceId }) => {
  const { user } = useAuth();

  return (
    <nav className="bg-white shadow">
      <div className="container mx-auto px-4 flex items-center justify-between h-16">
        <Link to="/" className="font-['Orbitron'] text-xl font-bold">
          2xai
        </Link>
        <div className="flex items-center">
          {user && (
            <NavbarDropdown 
              userName={user.full_name || user.name} 
              userAvatar={user.photo}
            />
          )}
        </div>
      </div>
    </nav>
  );
};
