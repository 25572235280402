// src/hooks/useVerification.ts
import { useState } from 'react';
import { useVerifyEmailMutation, useVerifyPhoneMutation } from '@api/auth.api';
import { toast } from 'react-toastify';

interface UseVerificationProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export type VerificationParams = {
  email?: string;
  phone?: string;
  code: string;
};

export const useVerification = ({ onSuccess, onError }: UseVerificationProps = {}) => {
  const [verifyEmail] = useVerifyEmailMutation();
  const [verifyPhone] = useVerifyPhoneMutation();
  const [timeLeft, setTimeLeft] = useState(0);
  const [isSending, setIsSending] = useState(false);

  const handleSendCode = async (type: 'phone' | 'email', contact: string) => {
    try {
      setIsSending(true);
      // Здесь можно добавить логику отправки кода, если потребуется
      setTimeLeft(60);
      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      
      toast.success('Код верификации отправлен');
    } catch (error) {
      toast.error('Ошибка при отправке кода');
      onError?.(error);
    } finally {
      setIsSending(false);
    }
  };

  const handleVerifyCode = async (params: VerificationParams) => {
    try {
      if ('email' in params && params.email) {
        await verifyEmail({ email: params.email, code: params.code }).unwrap();
      } else if (params.phone) {
        await verifyPhone({  code: params.code }).unwrap();
      }
      toast.success('Верификация успешно завершена');
      onSuccess?.();
    } catch (error) {
      toast.error('Неверный код верификации');
      onError?.(error);
    }
  };

  return {
    sendCode: handleSendCode,
    verifyCode: handleVerifyCode,
    isSending,
    timeLeft
  };
};
