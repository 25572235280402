// src/components/atoms/Button/Button.tsx
import React, { ComponentPropsWithoutRef } from 'react';
import { cn } from '@utils/formatters';

type BaseButtonProps = {
  variant?: 'primary' | 'secondary' | 'white' | 'outline';
  size?: 'sm' | 'md' | 'lg' | 'large';
  className?: string;
}

type ButtonProps<T extends React.ElementType = 'button'> = BaseButtonProps & {
  as?: T;
} & ComponentPropsWithoutRef<T>

export const Button = <T extends React.ElementType = 'button'>({
  variant = 'primary',
  size = 'md',
  as,
  className,
  children,
  ...props
}: ButtonProps<T>) => {
  const Component = as || 'button';
  const baseStyles = 'rounded-lg focus:outline-none transition-all duration-300';
  const variantStyles = {
    primary: 'bg-blue-600 text-white hover:bg-blue-700 active:bg-blue-800',
    secondary: 'bg-gray-100 text-gray-900 hover:bg-gray-200 active:bg-gray-300',
    white: 'bg-white text-blue-600 hover:bg-gray-50 active:bg-gray-100',
    outline: 'bg-transparent border border-current hover:bg-opacity-10'
  };
  const sizeStyles = {
    sm: 'px-3 py-1.5 text-sm',
    md: 'px-4 py-2 text-base',
    lg: 'px-6 py-3 text-lg',
    large: 'px-6 py-3 text-lg'
  };

  return (
    <Component
      className={cn(baseStyles, variantStyles[variant], sizeStyles[size], className ?? '')}
      {...props}
    >
      {children}
    </Component>
  );
};
