import React from 'react';

interface EmptyStateCardProps {
  emoji: string;
  title: string;
  description: string;
}

export const EmptyStateCard: React.FC<EmptyStateCardProps> = ({
  emoji,
  title,
  description
}) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100 hover:shadow-md transition-shadow">
      <div className="text-4xl mb-4">{emoji}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};