import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/user.types';

interface AuthState {
  token: string | null;
  user: User | null;
  isInitialized: boolean;
}

const initialState: AuthState = {
  token: localStorage.getItem('token'),
  user: null,
  isInitialized: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    clearAuth(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem('token');
    },
    getToken(state) {
      state.token = localStorage.getItem('token') || null;
    },
    initAuth(state) {
      state.token = localStorage.getItem('token');
      state.isInitialized = true;
    }
  }
});

export const { setToken, setUser, clearAuth, initAuth } = authSlice.actions;
export default authSlice.reducer;
