// src/components/pages/Auth/LoginPage/LoginPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { LoginForm } from '@components/organisms/Forms/LoginForm/LoginForm';
import { SocialAuthButton } from '@components/molecules/SocialAuthButton/SocialAuthButton';

export const LoginPage: React.FC = () => {
  const handleSocialAuth = (provider: string) => {
    console.log(`Авторизация через ${provider}`);
    // Здесь будет логика авторизации
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
        <div className="text-center mb-8">
          <h1 className="font-['Orbitron'] text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 text-transparent bg-clip-text">
            2xai
          </h1>
        </div>
        <h2 className="text-2xl font-semibold text-center mb-6">Вход в систему</h2>
        
        <LoginForm />

        <div className="mt-6">
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">или войти через</span>
            </div>
          </div>

          <div className="mt-6 space-y-3">
            <SocialAuthButton 
              provider="yandex"
              onClick={() => handleSocialAuth('yandex')}
            />
            <SocialAuthButton 
              provider="vk"
              onClick={() => handleSocialAuth('vk')}
            />
            <SocialAuthButton 
              provider="telegram"
              onClick={() => handleSocialAuth('telegram')}
            />
          </div>
        </div>

        <div className="mt-6 text-center text-sm">
          <span className="text-gray-600">Еще нет аккаунта? </span>
          <Link 
            to="/register" 
            className="font-medium text-blue-600 hover:text-blue-500"
          >
            Зарегистрироваться
          </Link>
        </div>
      </div>
    </div>
  );
};
