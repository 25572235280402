// src/components/atoms/Alert/Alert.tsx
import React from 'react';
import { cn } from '@utils/formatters';

interface AlertProps {
  type?: 'success' | 'error' | 'warning' | 'info';
  message: string | string[];
  className?: string;
}

export const Alert: React.FC<AlertProps> = ({
  type = 'info',
  message,
  className
}) => {
  const typeStyles = {
    success: 'bg-green-100 text-green-700 border-green-200',
    error: 'bg-red-100 text-red-700 border-red-200',
    warning: 'bg-yellow-100 text-yellow-700 border-yellow-200',
    info: 'bg-blue-100 text-blue-700 border-blue-200'
  };

  return (
    <div
      className={cn(
        'p-4 rounded border',
        typeStyles[type],
        className ?? ''
      )}
    >
      {Array.isArray(message) ? (
        <ul className="list-disc list-inside">
          {message.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      ) : (
        message
      )}
    </div>
  );
};
