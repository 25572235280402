export const API_ROUTES = {
  AUTH: {
    REGISTER: '/auth/register',
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
    VERIFY_EMAIL: '/auth/verify/email',
    VERIFY_PHONE: '/auth/verify/phone',
    OAUTH: {
      VK: '/auth/vk/callback',
      YANDEX: '/auth/yandex/callback',
      TELEGRAM: '/auth/telegram/callback'
    }
  },
  SPACES: {
    BASE: '/api/spaces/',
    GET_BY_ID: (id: string) => `/api/spaces/${id}`,
    MEMBERS: {
      LIST: (spaceId: string) => `/api/spaces/${spaceId}/members`,
      ADD: (spaceId: string) => `/api/spaces/${spaceId}/members`,
      REMOVE: (spaceId: string, userId: string) => 
        `/api/spaces/${spaceId}/members/${userId}`,
      UPDATE_ROLE: (spaceId: string, userId: string) => 
        `/api/spaces/${spaceId}/members/${userId}/role`
    }
  },
  USERS: {
    ME: '/api/users/me',
    UPDATE_PROFILE: '/api/users/me/profile',
    UPDATE: '/api/users/me/',
    CHANGE_PASSWORD: '/api/users/me/password',
    MY_SPACES: '/api/users/me/spaces'
  },
  PLANS: {
    LIST: '/api/plans/',
    CURRENT: (spaceId: string) => `/api/plans/current/${spaceId}`
  }
}; 