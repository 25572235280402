// src/components/templates/MainTemplate/MainTemplate.tsx
import React from 'react';
import { Navbar } from '@components/organisms/Navbar/Navbar';

interface MainTemplateProps {
  children: React.ReactNode;
}

export const MainTemplate: React.FC<MainTemplateProps> = ({ children }) => (
  <div>
    <Navbar />
    <main className="container mx-auto px-4 py-6">{children}</main>
  </div>
);
