// src/components/pages/Auth/RegisterPage/RegisterPage.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { AuthTemplate } from '@components/templates/AuthTemplate/AuthTemplate';
import { RegisterForm } from '@components/organisms/Forms/RegisterForm/RegisterForm';

export const RegisterPage: React.FC = () => (
  <AuthTemplate>
    <div className="text-center mb-8">
      <h1 className="font-['Orbitron'] text-4xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 text-transparent bg-clip-text">
        2xai
      </h1>
    </div>
    <h2 className="text-2xl font-semibold text-center mb-6">Регистрация</h2>
    <RegisterForm />
    <div className="mt-6 text-center text-sm">
      <span className="text-gray-600">Уже есть аккаунт? </span>
      <Link 
        to="/login" 
        className="font-medium text-blue-600 hover:text-blue-500"
      >
        Войти
      </Link>
    </div>
  </AuthTemplate>
);
