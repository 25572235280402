import { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { initAuth } from '@store/slices/auth.slice';
import { LoginPage } from './components/pages/Auth/LoginPage/LoginPage';
import { RegisterPage } from './components/pages/Auth/RegisterPage/RegisterPage';
import { VerificationPage } from './components/pages/Auth/VerificationPage/VerificationPage';
import { SpacesListPage } from './components/pages/Spaces/SpacesListPage/SpacesListPage';
import { SpacePage } from './components/pages/Spaces/SpacePage/SpacePage';
import { RequireAuth } from './components/organisms/RequireAuth/RequireAuth';

function App() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(initAuth());
  }, [dispatch]);

  return (
    <>
      <Toaster position="top-right" />
      <GoogleReCaptchaProvider reCaptchaKey="6Lco0ngqAAAAAE1_9c37pdnFvTK1r4JJJKZbBtWK">
        <Suspense 
          fallback={
            <div className="flex items-center justify-center min-h-screen">
              Загрузка...
            </div>
          }
        >
          <Routes>
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="verify" element={<VerificationPage />} />
            <Route
              path="spaces"
              element={
                <RequireAuth>
                  <SpacesListPage />
                </RequireAuth>
              }
            />
            <Route
              path="/spaces/:spaceId/*"
              element={
                <RequireAuth>
                  <SpacePage />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Suspense>
      </GoogleReCaptchaProvider>
    </>
  );
}

export default App;