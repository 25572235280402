import React from 'react';

export const Analytics: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Аналитика</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Здесь будут графики и метрики */}
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-medium mb-2">Общая статистика</h3>
          <p className="text-gray-600">
            Здесь будут отображаться основные метрики вашего канала
          </p>
        </div>
      </div>
    </div>
  );
}; 