import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';
import { API_ROUTES } from '@config/api.routes';
import { User } from '../types/user.types';
import { Space } from '../types/space.types';
import { UserProfileUpdateRequest } from '../types/user.types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'UserSpaces'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => API_ROUTES.USERS.ME,
      providesTags: ['User']
    }),

    updateUser: builder.mutation<User, Partial<User>>({
      query: (data) => ({
        url: API_ROUTES.USERS.UPDATE,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['User']
    }),

    changePassword: builder.mutation<void, { currentPassword: string; newPassword: string }>({
      query: (data) => ({
        url: API_ROUTES.USERS.CHANGE_PASSWORD,
        method: 'PUT',
        body: data
      })
    }),

    getUserSpaces: builder.query<Space[], void>({
      query: () => API_ROUTES.USERS.MY_SPACES,
      providesTags: ['UserSpaces']
    }),

    updateProfile: builder.mutation<User, UserProfileUpdateRequest>({
      query: (data) => ({
        url: API_ROUTES.USERS.UPDATE_PROFILE,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: ['User']
    })
  })
});

export const {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
  useChangePasswordMutation,
  useGetUserSpacesQuery,
  useUpdateProfileMutation
} = usersApi;

// Добавляем пустой экспорт для решения проблемы с модулем
export type { User }; 