// src/components/organisms/Forms/EmailVerificationForm/EmailVerificationForm.tsx
import React from 'react';
import { useVerification } from '@hooks/useVerification';
import { VerificationForm } from '../VerificationForm/VerificationForm';
import { Button } from '@components/atoms/Button/Button';

interface EmailVerificationFormProps {
  email: string;
  onSuccess?: () => void;
}

export const EmailVerificationForm: React.FC<EmailVerificationFormProps> = ({
  email,
  onSuccess
}) => {
  const { sendCode, timeLeft, isSending } = useVerification({
    onSuccess,
    onError: (error) => {
      console.error('Ошибка верификации:', error);
    }
  });

  const handleResendCode = () => {
    sendCode('email', email);
  };

  return (
    <div>
      <VerificationForm
        type="email"
        contact={email}
        onSuccess={onSuccess}
      />
      
      {timeLeft > 0 ? (
        <p className="text-sm text-gray-500 mt-2">
          Повторная отправка кода через {timeLeft} сек.
        </p>
      ) : (
        <Button 
          onClick={handleResendCode}
          disabled={isSending}
          variant="secondary"
          className="mt-2 w-full"
        >
          {isSending ? 'Отправка...' : 'Отправить код повторно'}
        </Button>
      )}
    </div>
  );
};
