import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormField } from '@components/molecules/FormField/FormField';
import { useUpdateProfileMutation } from '@api/users.api';
import { useCreateSpaceMutation } from '@api/spaces.api';
import { useAuth } from '@hooks/useAuth';
import { toast } from 'react-hot-toast';

type WizardStep = 'profile' | 'username' | 'space';

interface CreateSpaceWizardProps {
  isOpen: boolean;
  onClose: () => void;
}

const profileSchema = z.object({
  fullName: z
    .string()
    .min(2, 'Минимум 2 символа')
    .max(50, 'Максимум 50 символов')
    .regex(/^[а-яёА-ЯЁ\s-]+$/, 'Только русские буквы, пробел и дефис')
});

const usernameSchema = z.object({
  username: z
    .string()
    .min(3, 'Минимум 3 символа')
    .max(30, 'Максимум 30 символов')
    .regex(/^[a-z0-9_]+$/, 'Только латинские буквы, цифры и подчеркивания')
});

const spaceSchema = z.object({
  name: z.string().min(1, 'Обязательное поле'),
  description: z.string().optional()
});

type ProfileFormValues = z.infer<typeof profileSchema>;
type UsernameFormValues = z.infer<typeof usernameSchema>;
type SpaceFormValues = z.infer<typeof spaceSchema>;

export const CreateSpaceWizard: React.FC<CreateSpaceWizardProps> = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState<WizardStep>('profile');
  const { user } = useAuth();
  const [updateUser] = useUpdateProfileMutation();
  const [createSpace] = useCreateSpaceMutation();

  const profileForm = useForm<ProfileFormValues>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      fullName: user?.full_name || ''
    }
  });

  const usernameForm = useForm<UsernameFormValues>({
    resolver: zodResolver(usernameSchema),
    defaultValues: {
      username: user?.name || ''
    }
  });

  const spaceForm = useForm<SpaceFormValues>({
    resolver: zodResolver(spaceSchema),
    defaultValues: {
      name: '',
      description: ''
    }
  });

  const handleProfileSubmit = async (data: ProfileFormValues) => {
    try {
      await updateUser({ 
        username: data.fullName
      }).unwrap();
      setCurrentStep('username');
    } catch (error) {
      console.error('Ошибка обновления имени:', error);
    }
  };

  const handleUsernameSubmit = async (data: UsernameFormValues) => {
    try {
      await updateUser({ 
        name: data.username
      }).unwrap();
      setCurrentStep('space');
    } catch (error) {
      console.error('Ошибка обновления имени пользователя:', error);
    }
  };

  const handleSpaceSubmit = async (data: SpaceFormValues) => {
    try {
      await createSpace(data).unwrap();
      onClose();
    } catch (error) {
      console.error('Ошибка создания пространства:', error);
      toast.error('Не удалось создать пространство. Пожалуйста, попробуйте позже.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        {currentStep === 'profile' ? (
          <>
            <div className="text-center mb-6">
              <div className="text-4xl mb-2">👤</div>
              <h2 className="text-xl font-semibold">Как вас зовут?</h2>
              <p className="text-gray-500 text-sm mt-1">
                Введите ваше полное имя
              </p>
            </div>
            <form onSubmit={profileForm.handleSubmit(handleProfileSubmit)}>
              <FormField
                label="Полное имя"
                register={profileForm.register('fullName')}
                error={profileForm.formState.errors.fullName?.message}
                placeholder="Виктор Иванов"
              />
              <div className="mt-4 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md"
                >
                  Отмена
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                >
                  Продолжить
                </button>
              </div>
            </form>
          </>
        ) : currentStep === 'username' ? (
          <>
            <div className="text-center mb-6">
              <div className="text-4xl mb-2">🔤</div>
              <h2 className="text-xl font-semibold">Придумайте имя пользователя</h2>
              <p className="text-gray-500 text-sm mt-1">
                Это имя будет использоваться в URL вашего профиля
              </p>
            </div>
            <form onSubmit={usernameForm.handleSubmit(handleUsernameSubmit)}>
              <FormField
                label="Имя пользователя"
                register={usernameForm.register('username')}
                error={usernameForm.formState.errors.username?.message}
                placeholder="victor_ivanov"
              />
              <div className="mt-4 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setCurrentStep('profile')}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md"
                >
                  Назад
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                >
                  Продолжить
                </button>
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="text-center mb-6">
              <div className="text-4xl mb-2">🚀</div>
              <h2 className="text-xl font-semibold">Создайте пространство</h2>
              <p className="text-gray-500 text-sm mt-1">
                Пространство - это место для управления вашими проектами
              </p>
            </div>
            <form onSubmit={spaceForm.handleSubmit(handleSpaceSubmit)}>
              <FormField
                label="Название пространства"
                register={spaceForm.register('name')}
                error={spaceForm.formState.errors.name?.message}
                placeholder="Мой проект"
              />
              <FormField
                label="Описание"
                register={spaceForm.register('description')}
                error={spaceForm.formState.errors.description?.message}
                placeholder="Краткое описание проекта"
              />
              <div className="mt-4 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setCurrentStep('username')}
                  className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 rounded-md"
                >
                  Назад
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                >
                  Создать пространство
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};