// src/components/organisms/Forms/RegisterForm/RegisterForm.tsx
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { registerSchema } from '@utils/validation';
import { useRegisterMutation } from '@api/auth.api';
import { FormField } from '@components/molecules/FormField/FormField';
import { formatPhoneNumber, cleanPhoneNumber } from '@utils/formatters';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ServerError } from '../../../../types/error.types';
import { Alert } from '@components/atoms/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '@store/slices/auth.slice';

interface RegisterFormInputs {
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  recaptchaToken: string;
}

export const RegisterForm: React.FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [registerUser, { isLoading }] = useRegisterMutation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<RegisterFormInputs>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      phone: '+7'
    }
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formatPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = formatPhoneNumber(e.target.value);
  };

  const onSubmit = async (data: RegisterFormInputs) => {
    try {
      if (!executeRecaptcha) {
        throw new Error('Execute recaptcha not yet available');
      }
      
      const recaptchaToken = await executeRecaptcha('register');
      
      const registerData = {
        email: data.email,
        phone: cleanPhoneNumber(data.phone),
        password: data.password,
        confirmPassword: data.confirmPassword,
        recaptchaToken
      };

      const response = await registerUser(registerData).unwrap();
      
      if (response.token) {
        dispatch(setToken(response.token));
        console.log("Token Set + response.token = ", response.token)
      }

      navigate('/svc/verify', {
        state: {
          phone: data.phone,
          email: data.email,
          step: 'phone'
        },
        replace: true
      });
      
    } catch (err) {
      console.log(err);     
      const error = err as ServerError;
      if (error.data?.errors) {
        Object.entries(error.data.errors).forEach(([field, messages]) => {
          setError(field as keyof RegisterFormInputs, {
            type: 'server',
            message: messages[0]
          });
        });
      } else {
        setError('root', {
          type: 'server',
          message: error.data?.message || 'Произошла ошибка при регистрации'
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {errors.root && (
        <Alert 
          type="error" 
          message={errors.root.message || 'Произошла ошибка при регистрации'} 
        />
      )}
      
      <FormField
        label="Email"
        type="email"
        register={register('email')}
        error={errors.email?.message}
        placeholder="example@domain.com"
      />

      <FormField
        label="Телефон"
        type="tel"
        register={register('phone')}
        error={errors.phone?.message}
        placeholder="+7"
        onChange={formatPhone}
      />

      <FormField
        label="Пароль"
        type="password"
        register={register('password')}
        error={errors.password?.message}
      />

      <FormField
        label="Подтверждение пароля"
        type="password"
        register={register('confirmPassword')}
        error={errors.confirmPassword?.message}
      />

      <div className="pt-2">
        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors duration-200"
        >
          {isLoading ? 'Загрузка...' : 'Зарегистрироваться'}
        </button>
      </div>
    </form>
  );
};
