// src/api/baseQueryWithReauth.ts
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { RootState } from '@store/store';
import { API_CONFIG } from '@config/api.config';
import { setToken } from '@store/slices/auth.slice';

const logRequest = (url: string, method: string, headers: Headers) => {
  console.group(`🚀 API Request: ${method} ${url}`);
  console.log('Headers:', Object.fromEntries(headers.entries()));
  console.groupEnd();
};

const logResponse = (url: string, method: string, status: number, data: any) => {
  console.group(`✨ API Response: ${method} ${url}`);
  console.log('Status:', status);
  console.log('Data:', data);
  console.groupEnd();
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_CONFIG.BASE_URL,
  timeout: API_CONFIG.TIMEOUT,
  credentials: API_CONFIG.CREDENTIALS,
  mode: 'cors',

  prepareHeaders: (headers, { getState }) => {
   
    const token = (getState() as RootState).auth.token;
    
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json');
    
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
      console.log('Headers after setting token:', Object.fromEntries(headers.entries()));
    }
    return headers;
  }
});

export const baseQueryWithReauth: BaseQueryFn<any, unknown, unknown> = async (args, api, extraOptions) => {
  // Логируем исходящий запрос
  const url = typeof args === 'string' ? args : args.url;
  const method = typeof args === 'string' ? 'GET' : args.method || 'GET';
  const headers = typeof args === 'string' ? new Headers() : new Headers(args.headers);
  
  logRequest(url, method, headers);

  let result = await baseQuery(args, api, extraOptions);

  // Проверяем наличие токена в ответе и обновляем его
  if (result.data && (result.data as any).token) {
    const newToken = (result.data as any).token;
    api.dispatch(setToken(newToken));
    console.log('✅ Token updated from response');
  }

  // Логируем ответ
  logResponse(url, method, result.meta?.response?.status || 0, result.data);

  if (result.error && result.error.status === 401) {
    console.log('🔄 Token expired, attempting refresh...');
    
    const refreshResult = await baseQuery(
      { url: '/auth/refresh', method: 'POST' },
      api,
      extraOptions
    );

    if (refreshResult.data && (refreshResult.data as any).token) {
      const refreshedToken = (refreshResult.data as any).token;
      api.dispatch(setToken(refreshedToken));
      console.log('🔑 Token refreshed successfully');
      result = await baseQuery(args, api, extraOptions);
      
      // Логируем повторный запрос после обновления токена
      logResponse(url, method, result.meta?.response?.status || 0, result.data);
    } else {
      console.log('❌ Token refresh failed');
    }
  }

  return result;
};
