// src/api/auth.api.ts
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';
import { API_ROUTES } from '@config/api.routes';
import {
  RegisterRequest,
  LoginRequest,
  OAuthResponse,
  
} from '../types/auth.types';
import { User } from '../types/user.types';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    register: builder.mutation<{ token: string; user: User }, RegisterRequest>({
      query: (data) => ({
        url: API_ROUTES.AUTH.REGISTER,
        method: 'POST',
        body: data
      })
    }),
    
    verifyEmail: builder.mutation<void, { email: string, code: string }>({
      query: (data) => ({
        url: API_ROUTES.AUTH.VERIFY_EMAIL,
        method: 'POST',
        body: data
      })
    }),
    
    verifyPhone: builder.mutation<void, { code: string }>({
      query: (data) => ({
        url: API_ROUTES.AUTH.VERIFY_PHONE,
        method: 'POST',
        body: data
      })
    }),
    
    login: builder.mutation<{ token: string }, LoginRequest>({
      query: (data) => ({
        url: API_ROUTES.AUTH.LOGIN,
        method: 'POST',
        body: data
      })
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: API_ROUTES.AUTH.LOGOUT,
        method: 'POST'
      })
    }),

    oauthVK: builder.query<OAuthResponse, void>({
      query: () => API_ROUTES.AUTH.OAUTH.VK
    }),

    oauthYandex: builder.query<OAuthResponse, void>({
      query: () => API_ROUTES.AUTH.OAUTH.YANDEX
    }),

    oauthTelegram: builder.mutation<OAuthResponse, { data: string }>({
      query: (data) => ({
        url: API_ROUTES.AUTH.OAUTH.TELEGRAM,
        method: 'POST',
        body: data
      })
    })
  })
});

export const {
  useRegisterMutation,
  useVerifyEmailMutation,
  useVerifyPhoneMutation,
  useLoginMutation,
  useLogoutMutation,
  useOauthVKQuery,
  useOauthYandexQuery,
  useOauthTelegramMutation
} = authApi;
