import React, { useState } from 'react';
import { EmptyStateCard } from '@components/molecules/EmptyStateCard/EmptyStateCard';

export const MyChannels: React.FC = () => {
  const [channelUsername, setChannelUsername] = useState('');

  const handleAddChannel = (e: React.FormEvent) => {
    e.preventDefault();
    // Здесь будет логика добавления канала
    console.log('Добавление канала:', channelUsername);
    setChannelUsername('');
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Мои каналы</h1>
      
      <div className="mb-8">
        <form onSubmit={handleAddChannel} className="flex gap-4">
          <input
            type="text"
            value={channelUsername}
            onChange={(e) => setChannelUsername(e.target.value)}
            placeholder="Введите @username канала"
            className="flex-1 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors"
          >
            Добавить канал
          </button>
        </form>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <EmptyStateCard
          emoji="📊"
          title="Аналитика каналов"
          description="Отслеживайте статистику и метрики ваших Telegram-каналов в реальном времени"
        />
        <EmptyStateCard
          emoji="🤖"
          title="AI-ассистент"
          description="Используйте искусственный интеллект для генерации контента и анализа аудитории"
        />
        <EmptyStateCard
          emoji="📅"
          title="Планирование"
          description="Создавайте контент-планы и автоматизируйте публикации в ваших каналах"
        />
      </div>

      <div className="mt-8 bg-blue-50 rounded-lg p-6">
        <h3 className="text-lg font-semibold mb-4">Зачем добавлять каналы?</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="space-y-2">
            <div className="text-2xl">📈</div>
            <h4 className="font-medium">Единая аналитика</h4>
            <p className="text-gray-600">Анализируйте все ваши каналы в одном месте с подробной статистикой</p>
          </div>
          <div className="space-y-2">
            <div className="text-2xl">⚡️</div>
            <h4 className="font-medium">Автоматизация</h4>
            <p className="text-gray-600">Настройте автопостинг и управляйте контентом через единый интерфейс</p>
          </div>
          <div className="space-y-2">
            <div className="text-2xl">🎯</div>
            <h4 className="font-medium">Оптимизация</h4>
            <p className="text-gray-600">Получайте AI-рекомендации по улучшению контента и вовлеченности</p>
          </div>
        </div>
      </div>
    </div>
  );
}; 