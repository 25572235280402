import React from 'react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { SpaceTemplate } from '@components/templates/SpaceTemplate/SpaceTemplate';
import { MyChannels } from '@components/organisms/MyChannels/MyChannels';
import { Competitors } from '@components/organisms/Competitors/Competitors';
import { Analytics } from '@components/organisms/Analytics/Analytics';
import { ContentPlans } from '@components/organisms/ContentPlans/ContentPlans';
import { Billing } from '@components/organisms/Billing/Billing';
import { Settings } from '@components/organisms/Settings/Settings';
import { useGetSpaceByIdQuery } from '@api/spaces.api';

export const SpacePage: React.FC = () => {
  const { spaceId } = useParams<{ spaceId: string }>();
  const { isLoading, isError } = useGetSpaceByIdQuery(spaceId!);

  // Если запрос завершился с ошибкой и это не ошибка загрузки
  if (isError && !isLoading) {
    return <Navigate to="/svc/spaces" replace />;
  }

  return (
    <SpaceTemplate spaceId={spaceId!} isLoading={isLoading}>
      <Routes>
        <Route index element={<Navigate to="channels" replace />} />
        <Route path="channels" element={<MyChannels />} />
        <Route path="competitors" element={<Competitors />} />
        <Route path="analytics" element={<Analytics />} />
        <Route path="content-plans" element={<ContentPlans />} />
        <Route path="billing" element={<Billing />} />
        <Route path="settings" element={<Settings />} />
      </Routes>
    </SpaceTemplate>
  );
};