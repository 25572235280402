import React from 'react';

interface CreateSpaceButtonProps {
  onClick: () => void;
}

export const CreateSpaceButton: React.FC<CreateSpaceButtonProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      <span className="mr-2">+</span>
      Создать пространство
    </button>
  );
}; 