import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { verificationSchema } from '@utils/validation';
import { useVerification } from '@hooks/useVerification';
import { FormField } from '@components/molecules/FormField/FormField';
import { Button } from '@components/atoms/Button/Button';
import { Alert } from '@components/atoms/Alert/Alert';

interface VerificationFormProps {
  type: 'email' | 'phone';
  contact: string;
  onSuccess?: () => void;
}

interface VerificationFormInputs {
  code: string;
}

export const VerificationForm: React.FC<VerificationFormProps> = ({
  type,
  contact,
  onSuccess
}) => {
  const { verifyCode, isSending, timeLeft } = useVerification({ onSuccess });
  
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<VerificationFormInputs>({
    resolver: zodResolver(verificationSchema)
  });

  const onSubmit = async (data: VerificationFormInputs) => {
    try {
      await verifyCode(
        type === 'email'
          ? { email: contact, code: data.code }
          : { phone: contact, code: data.code }
      );
    } catch (err) {
      // Обработка ошибок
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <Alert 
        type="info" 
        message={`Код подтверждения отправлен на ${contact}`} 
      />
      
      <FormField
        label="Код подтверждения"
        register={register('code')}
        error={errors.code?.message}
        placeholder="Введите код"
      />

      {isSending && (
        <Alert 
          type="info" 
          message={`Код отправлен, осталось ${timeLeft} секунд`} 
        />
      )}

      <Button type="submit" disabled={isSending} className="w-full">
        {isSending ? 'Проверка...' : 'Подтвердить'}
      </Button>
    </form>
  );
}; 