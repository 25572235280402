// src/components/organisms/Forms/LoginForm/LoginForm.tsx
import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { loginSchema } from '@utils/validation';
import { useLoginMutation } from '@api/auth.api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '@store/slices/auth.slice';



interface LoginFormInputs {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit
  } = useForm<LoginFormInputs>({
    resolver: zodResolver(loginSchema)
  });

  const onSubmit = async (data: LoginFormInputs) => {
    try {
      const response = await login(data).unwrap();
      if (response.token) {
        dispatch(setToken(response.token));
        navigate('/spaces');
      }
    } catch (err) {
      // Обработка ошибок
    }
  };

  

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <label className="block text-gray-700 mb-1">Email</label>
        <input
          type="email"
          {...register('email')}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>

      <div className="mb-6">
        <label className="block text-gray-700 mb-1">Пароль</label>
        <input
          type="password"
          {...register('password')}
          className="w-full border border-gray-300 p-2 rounded"
        />
      </div>

      <div className="flex justify-center">
        <button
          type="submit"
          disabled={isLoading}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded transition-colors duration-200"
        >
          {isLoading ? 'Загрузка...' : 'Войти'}
        </button>
      </div>
    </form>
  );
};
