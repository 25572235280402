// src/components/organisms/RequireAuth/RequireAuth.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '@store/store';

interface RequireAuthProps {
  children: React.ReactNode;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const token = useSelector((state: RootState) => state.auth.token);
  const isInitialized = useSelector((state: RootState) => state.auth.isInitialized);

  if (!isInitialized) {
    return null; // или loading spinner
  }

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};
