import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';
import { API_ROUTES } from '@config/api.routes';
import { Plan, PlansResponse } from '../types/plan.types';

export const plansApi = createApi({
  reducerPath: 'plansApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getPlans: builder.query<Plan[], void>({
      query: () => API_ROUTES.PLANS.LIST,
      transformResponse: (response: PlansResponse) => response.plans
    }),
    
    getCurrentPlan: builder.query<{ plan: Plan }, string>({
      query: (spaceId) => API_ROUTES.PLANS.CURRENT(spaceId),
      transformResponse: (response: { plan: Plan }) => response
    }),
    
  })
});

export const {
  useGetPlansQuery,
  useGetCurrentPlanQuery,
} = plansApi; 