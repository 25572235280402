import React from 'react';

export const ContentPlans: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Контент-планы</h1>
      <div className="bg-white rounded-lg shadow p-4">
        <div className="mb-4">
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
            Создать новый план
          </button>
        </div>
        <div className="space-y-4">
          {/* Здесь будет календарь и список постов */}
          <p className="text-gray-600">
            Планируйте и организуйте ваш контент эффективно
          </p>
        </div>
      </div>
    </div>
  );
}; 